import styled from '@emotion/styled';
import { complementaryColor } from '@/utils/colors';

type ProjectProps = {
  img_src: string;
  title: string;
  date: string;
};

const Project = ({ img_src, title, date }: ProjectProps) => {
  const Cover = styled.div`
    position: relative;
    flex-grow: 1;
    margin-bottom: 16px;
    border: 1px solid ${({ theme }) => theme.colors.fontColor};
    &:last-of-type {
      margin-bottom: 0;
    }
    & > img {
      display: block;
      width: 250px;
      height: 156px;
    }
    &:hover > div {
      opacity: 1;
      transition: opacity 0.2s ease;
    }
  `;
  const Info = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    padding: 30px 16px;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) =>
      complementaryColor(theme.colors.backgroundColor)}66;
    opacity: 0;
    transition: opacity 0.2s ease;
  `;
  const Title = styled.div`
    margin-bottom: 16px;
    font-size: 18px;
    color: #fff;
  `;
  const Date = styled.div`
    color: #fffa;
  `;
  return (
    <Cover>
      <img src={img_src} alt={title} />
      <Info>
        <Title>{title}</Title>
        <Date>{date}</Date>
      </Info>
    </Cover>
  );
};

export default Project;
