import { useState } from 'react';
import styled from '@emotion/styled';
import Project from './project';
import throttle from '@/utils/simple-throttle';
import CommonStyle from '@/style';

type ProjectListProps = {
  project_list: {
    img_src: string;
    title: string;
    date: string;
  }[];
};

const ProjectList = ({ project_list }: ProjectListProps) => {
  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener(
    'resize',
    throttle(() => setWidth(window.innerWidth), 10)
  );
  const Container = styled.div`
    overflow-y: scroll;
    position: absolute;
    left: ${width / 2 - 700}px;
    top: 0;
    display: none;
    padding: 10px;
    padding-top: calc(${CommonStyle.headerHeight} + 10px);
    max-width: 300px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    @media (min-width: 1400px) {
      display: block;
    }
  `;
  return (
    <Container>
      {project_list.map((p) => (
        <Project
          key={p.img_src}
          img_src={p.img_src}
          title={p.title}
          date={p.date}
        />
      ))}
    </Container>
  );
};

export default ProjectList;
