import React from 'react';
import Container from '@/components/page-container';
import { ProjectList } from '@/components/projects';

const Projects: React.FC = () => {
  const project_list = [
    {
      img_src: '0',
      title: 'Test',
      date: '2021.05.16 ~ 2021.05.16',
    },
  ];
  return (
    <Container>
      <ProjectList project_list={project_list} />
    </Container>
  );
};

export default Projects;
